import axios from "axios";

const INVOICES_URL = 'api/v1/invoices'

export default class InvoiceService {
    getAll(queryParam) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${INVOICES_URL}/list`, {params: queryParam}).then(res => res.data)
    }

    getOne(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${INVOICES_URL}/${id}`).then(res => res.data)
    }

    getBookings(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${INVOICES_URL}/${id}/bookings`).then(res => res.data)
    }

    updateBookingStatuses(invoiceID, payload) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${INVOICES_URL}/${invoiceID}`, payload)
    }

    editOperationalFee(id, operationalFee) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${INVOICES_URL}/${id}/operationalfee`, {
            operational_fee: operationalFee
        })
    }

    createQRIS(invoice_id) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/payment/qrcode`, {
            invoice_id: invoice_id,
        })
        .then(res => res)
        .catch((e) => e.response)
    }

    getQRISDetail(invoice_id, trx_code) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/payment/qrcode/detail?invoice_id=${invoice_id}&trx_code=${trx_code}`)
    }

    updateBookingStatus(id) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/booking/${id}/status`).then(res => res).catch(e => e.response)
    }
}
