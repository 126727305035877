<template>
  <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <router-link
        :to="{ name: 'invoices' }"
        class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
      >
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <!-- <Button label="Edit Pesanan" 
        icon="pi pi-pencil" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
      /> -->
    </div>
    <h5 class="tw-text-xl inter-semibold tw-text-black">Detail Pesanan</h5>
  </div>

  <div class="tw-p-6 md:tw-p-10">
    <div class="card tw-mb-10">
      <div class="grid">
        <div class="col-12 sm:col-6 md:col-4">
          <h3 class="mb-3 tw-text-lg inter-semibold">QR Code</h3>
          <div class="grid">
            <div class="col-12 text-center">
              <div
                class="p-2 overflow-hidden border-1 border-300 border-round tw-inline-block tw-mb-2"
                v-if="!qris.qrcode"
              >
                <QRCodeVue3
                  :width="120"
                  :height="120"
                  :value="'QR Code will be generated after update services status'"
                  :dotsOptions="{ type: 'rounded', color: '#dedede' }"
                  :cornersSquareOptions="{ type: 'square', color: '#dedede' }"
                  :cornersDotOptions="{ type: undefined, color: '#dedede' }"
                  fileExt="png"
                />
              </div>
              <div
                class="overflow-hidden border-1 border-300 border-round tw-inline-block tw-mb-2"
                v-else
              >
                <QRCodeVue3
                  :width="120"
                  :height="120"
                  :value="qris.qrcode"
                  :dotsOptions="{ type: 'rounded', color: '#000000' }"
                  :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                  fileExt="png"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 sm:col-6 md:col-7">
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nama Pelanggan
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.customer_name }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nomor Invoice
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.invoice_number }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Harga
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ formatCurrency(data.amount) }}
                </h6>
              </div>
            </div>

            <div class="col-12 md:col-6">
              <div class="tw-mb-6" v-if="isAdminPriti()">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nama Salon
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.salon_name }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Biaya Operasional
                </h6>
                <div>
                  <div
                    class="flex align-items-center"
                    v-if="!isEditOperationalFee"
                  >
                    <div class="mr-3">
                      {{ formatCurrency(data["operational_fee"]) }}
                    </div>
                    <div v-if="data.status === 0">
                      <Button
                        icon="pi pi-pencil"
                        class="px-2 py-1 mb-0 p-button-warning tw-text-sm"
                        label="edit"
                        @click="toggleEditOperationalFee"
                      />
                    </div>
                  </div>

                  <div
                    v-else
                    class="tw-flex tw-justify-between tw-items-center"
                  >
                    <div>
                      <InputNumber
                        v-model="operationalFee"
                        mode="currency"
                        currency="IDR"
                        locale="en-US"
                        class="tw-h-10"
                      />
                    </div>
                    <div class="tw-flex">
                      <Button
                        icon="pi pi-check"
                        class="p-button-rounded p-button-success"
                        @click="submitOperationalFee"
                      />
                      <Button
                        icon="pi pi-times"
                        class="p-button-rounded p-button-danger"
                        @click="toggleEditOperationalFee"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Status
                </h6>
                <div>
                  <Tag
                    class="mr-2 status status-warning"
                    value="Menunggu diproses"
                    v-if="data.status === 0"
                  ></Tag>
                  <Tag
                    class="mr-2 status status-info"
                    value="Belum dibayar"
                    v-else-if="data.status === 1"
                  ></Tag>
                  <Tag
                    class="mr-2 status status-success"
                    value="Sudah dibayar"
                    v-else-if="data.status === 2"
                  ></Tag>
                  <Tag
                    class="status status-danger"
                    value="Dibatalkan"
                    v-else-if="data.status === 3"
                  ></Tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <BookingList
        :invoice-id="route.params.id"
        :invoice="data"
        @getQRIS="getQRIS($event)"
        @updateInvoice="updateInvoice($event)"
      />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { useInvoiceDetail } from "@/composables/invoices";
import { useCommonUtils } from "@/composables/utils";
import { useAuthRole } from "@/composables/auth";
import QRCodeVue3 from "qrcode-vue3";
import BookingList from "./components/BookingList";

const route = useRoute();

const { data, getDetail, editOperationalFee } = useInvoiceDetail();
const { formatCurrency } = useCommonUtils();

const isEditOperationalFee = ref(false);
const operationalFee = ref(0);
const { isAdminPriti } = useAuthRole();

const toggleEditOperationalFee = () => {
  isEditOperationalFee.value = !isEditOperationalFee.value;
  operationalFee.value = data.value["operational_fee"];
};

const submitOperationalFee = async () => {
  await editOperationalFee(route.params.id, operationalFee.value);
  toggleEditOperationalFee();
};

const qris = ref({});

const getQRIS = (event) => {
  qris.value = event.value;
};

const updateInvoice = (value) => {
  data.value = value;
};

onBeforeMount(async () => {
  await getDetail(route.params.id);
  operationalFee.value = data.value["operational_fee"];
});
</script>

<style scoped lang="scss"></style>
